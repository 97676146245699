@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Air';
  src: url('/fonts/Aeonik/Aeonik-Air.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Air';
  src: url('/fonts/Aeonik/Aeonik-AirItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('/fonts/Aeonik/Aeonik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8fc;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /*  Typography mapped from Figma */
  .bigHeaderMedium {
    @apply font-sans not-italic font-medium text-3xl leading-8;
  }
  .bigHeaderBold {
    @apply font-sans not-italic font-bold text-3xl leading-8;
  }
  .h1 {
    @apply font-sans not-italic font-bold text-lg leading-snug;
  }
  .h2 {
    @apply font-sans not-italic font-bold text-xl leading-6;
  }
  .h3Bold {
    @apply font-sans not-italic font-bold text-base leading-5;
  }
  .h3Medium {
    @apply font-sans not-italic font-medium text-base leading-5;
  }
  .h3Regular {
    @apply font-sans not-italic font-normal text-base leading-5;
  }
  .capHeader {
    @apply font-sans not-italic font-bold text-sm leading-4;
  }
  .smallLink {
    background: red;
  }
  .smallText {
    @apply font-sans not-italic font-bold text-xs leading-3;
  }
  .smallButtonCap {
    background: red;
  }
  .mainButton {
    @apply text-base;
  }
  .filterHeader {
    background: red;
  }
  .calendarNumeric {
    background: red;
  }
  .links {
    background: red;
  }
}
